<template>
  <div>
    <el-form>
      <el-row :gutter="50">
        <el-col :span="24">
          <div class="form-group">
            <el-form-item label="Content">
              <el-input v-model="field.content" placeholder="Enter content" maxlength="200"></el-input>

             
           
        
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name:"templates-formComponents-SingleLineContent",
   components: {
      
   
  },
  props: ["field"],
  data() {
    return {};
  },
  mounted() {
       this.field['filled_by'] = 'SENDER';
  }
};
</script>

<style lang="scss" scoped>
  .el-input{
    &__inner{
       background-color: #eff3fa !important;
       border: 1px solid #cdddfc;
       box-shadow: 0 1px 2px 0 rgba(141, 141, 141, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.19);
    }
  }
  .el-textarea{
    &__inner{
    background-color:#eff3fa !important;
    border: 1px solid #cdddfc;
    box-shadow: 0 1px 2px 0 rgba(141, 141, 141, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.19);
    }
  }
  el-input, el-textarea{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
</style>